// Styled list
dl.floatList {
	@include pie-clearfix;

	dt, dd {
		display: block;
		vertical-align: top;
		float: left;
	}

	dt {
		padding-right: .5rem;
		clear:left;
		font-weight: bolder;
		width: 90%;
	}

	dd {
		margin-bottom: 1rem;
	}
}

@include breakpoint(tiny) {
	dt {
			width: 40%;
	}

	dd {
		width: 60%;
		margin-bottom: 0rem;
	}
}
// SOME HELPFUL CLASSES
//////////////////////////////

.highlight {
	color: $secondary;
}

.btn {
	background: $medium;
	border: none;
	color: $light;
	cursor: pointer;
	display: table;
	font-size: 1rem;
	line-height: 1rem;
	padding: .7rem 1rem;
	text-align: center;
	text-decoration: none;
	transition: 300ms all ease-in-out;

	&:hover, &:active, &:focus {
		background: $dark;
		color: $secondary;
	}

	*[class^="icon"] {
		margin-left: .5rem;
		vertical-align: middle;
	}
}

.notification {
	padding: 1em;
	background: $alert;
	color: $light;
	font-size: 1.2em;
}

// PAGE WRAP
//////////////////////////////

.page-wrap {
	padding-top:rem($menu-bar);

	@include breakpoint(large) {
		padding-top:0;
	}
}

// HEADER
//////////////////////////////

.header {
	position: relative;
	padding: rem(40px) 0 rem(0px) 0;
	width: 100%;

	.branding {
		display: block;
		float: left;
	}


	.topinfo {

		@include breakpoint(medium) {
			text-align: left;
		 }
		@include breakpoint(large) {
			text-align: right;
		 }


		span {
			display: none;

			@include breakpoint(large) {
				font-size: rem(24px);
				display: inline-block;
				font-size: rem(22px);
				font-weight: 300;
				margin-left: rem(20px);
			 }


			&.tel:before {
				@extend .fa-default;
				@extend .fa-phone-square;
				color: $secondary;
			}

			&.mail:before {
				@extend .fa-default;
				@extend .fa-envelope-square;
				color: $secondary;
			}

			&.fb a {
				display: block;
				text-align: left;
				background:url(/images/fb.png);
				width: 21px;
				height: 21px;
				text-indent: -9999px;
			}
		}
	}
}

.teaser {
	padding: rem(20px) 0 rem(0px) 0;

	@include breakpoint(medium) {
		padding: rem(40px) 0;
	 }


	.teasercontent {
		background:rgba($dark, 0.5) url(/images/rahmen.jpg) center center no-repeat;
		background-size: cover;
		border-bottom: rem(5px) solid $dark;
		padding: rem(80px) 0 rem(10px) 0;
		margin-bottom: rem(20px);

		@include breakpoint(small) {
			background-size: auto;
		}

		@include breakpoint(medium) {
			padding: rem(134px) rem(55px) rem(40px) rem(55px);
			margin-bottom: 0;
		 }


		a {
			display: inline-block;
			background:$secondary;
			color: $light;
			padding: rem(15px);
			text-transform: uppercase;
			font-size: rem(30px);
			font-weight: 300;

			&:before {
				@extend .fa-default;
				@extend .fa-arrow-circle-right;
				margin-right: rem(10px);
				font-size: rem(26px);
			}

			&:hover {
				color: $secondary;
				background:$dark;
			}
		}

		small {
			background:$dark;
			color: $light;
			text-transform: uppercase;
			letter-spacing:3px;
			display: inline-block;
			padding: rem(10px) rem(15px);
			font-size: rem(16px);

			@include breakpoint(medium) {
				font-size: rem(18px);
			 }

		}


	}

	.handwerk {
		background:rgba($dark, 0.5) url(/images/handwerk.jpg) center center no-repeat;
		background-size: cover;
		margin-bottom: 0;

		@include breakpoint(small) {
			background-size: auto;
		}
	}
}

// MAIN CONTENT
//////////////////////////////

main {
	display: block;

	.bg {
		background: rgba($light, 0.85);
		padding: rem(40px) rem(30px);
		margin-bottom: rem(40px);

		@include breakpoint(medium) {
		padding: rem(50px);
		 }

	}

	ul {
		@extend .styled-list;
	}

	.google-maps {
		@extend .video-container;

		@include breakpoint(medium) {
			height: rem(400px);
		}
	}
}

.bg-dark {
	background: rgba($dark, 0.85);
	padding: rem(20px) rem(50px) rem(0px) rem(50px);
	margin-bottom: rem(40px);
}

// FOOTER
//////////////////////////////

.footer {
	display: block;
	background: $dark;
	padding: rem(40px) 0;
	color: $light;

	p {
		@include breakpoint(large) {
			margin-bottom: 0;
		 }
	}

	a {
		&:hover {
			color: $light;
		}
	}

	hr {
		background:rgba($light, 0.3);
	}
}

#breakit {
	word-break: break-all;
}
