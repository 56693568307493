// Form styles
form.default {
    overflow: hidden;
    width: 100%;

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: rgba($dark, 0.5);
    }

    ::-moz-placeholder { /* Firefox 19+ */
        color: rgba($dark, 0.5);
    }

    :-ms-input-placeholder { /* IE 10+ */
        color: rgba($dark, 0.5);
    }

    :-moz-placeholder { /* Firefox 18- */
        color: rgba($dark, 0.5);
    }

    fieldset {
        border:none;
    }

    label, input, textarea, select, button, [type="submit"] {
        min-height: 2rem;
    }

    label {
        color: inherit;
        display: block;
        padding: 0.6rem 0 0.6rem;
        position:relative;

        @include breakpoint(medium) {
            width: 30%;
            float: left;
         }
        
        small {
            color: $alert;
            display: block;
            line-height: 0.5rem;
            position:absolute;
            font-size: rem(12px);
        }

        &[for="Zustimmung"] {
            margin-bottom:20px;
            width:80%;
            font-size: rem(16px);
            float: left;
            margin-bottom: rem(28px);
        }

        &.error {
            display: none !important;
        }
    }

    [type="text"], [type="tel"], [type="email"], [type="file"], input, textarea, select {
        background: $light;
        border: 1px rgba($dark, 0.2) solid;
        color: $dark;
        display: block;
        font-family: $main-font;
        font-size: 1rem;
        line-height: rem($base-line-height);
        margin: 0 0 0.5rem 0;
        padding: 0.9rem 0.6rem;
        position: relative;
        resize: none;
        transition: border .3s;
        width: 100%;
        

        @include breakpoint(medium) {
         float: left;
         width: 70%;
         }
        

        &:focus {
            background: darken($light, 2%);
            outline: none;
        }

        &.error {
            border: 1px dashed $secondary;
        }
    }

    textarea { /* fake line-height */
        min-height:122px;
        width: 100%;
       
    }

    [type="checkbox"] {
        display: block;
        float: right;
        margin: 0 0.75rem 0.5rem 0;
        width: 15px;
        background:transparent;
    }


    button, [type="submit"] {
        background: $secondary;
        text-transform: uppercase;
        font-weight: bold;
        border: none;
        color: $light;
        cursor: pointer;
        font-family: $main-font;
        font-size: 1rem;
        margin: 0 0 1.75rem;
        padding: 0.9rem $base-gap;
        width:100%;
        transition: 300ms all ease-in-out;

        &:hover, &:focus {
            background: $dark;
            transition: 300ms all ease-in-out;
        }
    }
}

/*=VALIDATION */

@keyframes error {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-webkit-keyframes error {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.specialfield {
    display: none !important;
    visibility:hidden !important;
}


img[name=vimg] + input[name=imgverify] {
    float:right;
}


.contact{

    form.default{

        ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
         color: $medium;
        }

        ::-moz-placeholder { /* Firefox 19+ */
            color: $medium;
        }

        :-ms-input-placeholder { /* IE 10+ */
            color: $medium;
        }

        :-moz-placeholder { /* Firefox 18- */
            color: $medium;
        }


        [type="text"], [type="tel"], [type="email"], [type="file"], textarea, select {
            background:#202123;
            border:rem(1px) solid $primary;
            margin-bottom:rem(1px);
            color:$light;
            width: 100%;

            &:hover, &:focus{
                border:rem(1px) solid $border;
                background:$dark;
                color:$light;
            }

           &.error {
                border: 1px dashed $secondary;
            }


        }

        label[for="Zustimmung"] {
            font-size: rem(12px);
            line-height: rem(18px);
            margin-bottom: 0;
        }



        fieldset{
            margin-bottom:0px;
        }

        @include breakpoint(small) {
            
            [type="text"], [type="tel"], [type="email"], [type="file"], input, textarea, select {
                margin-bottom:rem(4px);
            }

            button{
                margin-bottom:0px;
            }
        }

        @include breakpoint(medium) {   
            textarea{
                height:rem(100px);
            }
        }
        @include breakpoint(large) {
            label[for="Zustimmung"]{
                line-height:rem(17px);
            }           
        }
    }
}

form.default label.floatLabel {
    float: left;
    width: 90%;
    clear: both;
    margin-top: -0.5em;
    padding-bottom: 1em;
}

form.default input#Datenschutz {
    float: right;
    width: 1em;
    min-height: 0px!important;
    height: 1em;
    margin-top: 0.5em;
    margin-right: 0em;
}

input#Datenschutz.error {
-webkit-box-shadow: inset 0px 0px 6px 7px rgba(210,155,3,1);
-moz-box-shadow: inset 0px 0px 6px 7px rgba(210,155,3,1);
box-shadow: inset 0px 0px 6px 7px rgba(210,155,3,1);}