/*=NAVIGATION MAIN */
.navi-main {
	
	width: 100%;

	@include breakpoint(large) {
		display: flex;
		justify-content: space-between;
	}
	
	li {
		font-size: rem(20px);
		text-align: center;
		

		  	@include breakpoint(large) {
			  	display: inline-block;
			  	border-bottom: 0;
			  	text-align: center;
			 	position: relative;
				width: auto;
				font-size: rem(17px);
				

				&.impressum,
				&.datenschutzerklaerung,
				&.rahmen,
				&.handwerk,
				&.home,
				&.facebook {
					display: none;
				}
		   }

		   @include breakpoint(giant) {
		  	 font-size: rem(19px);
		    }

			&:hover {
				> a {
					color: $secondary;
				}
			}

			 &.active {
				a {
					color: $secondary;
				}
			}

			a {
				display: block;
				text-decoration: none;
				color: $primary;
				padding: rem(10px) 15px;
				border-bottom: 1px solid rgba($dark, 0.2);

				@include breakpoint(large) {
					padding: 0;
					border: none;
				 }
				
			}
		}

}


// Mobile Menu Bar
//////////////////////////////

$menu-bar: 60px;

.page-navi {

	// Off Canvas Navigation
	//////////////////////////////

	left:-100%;
	position: fixed;
	top:rem($menu-bar);
	transition:.6s;
	width:map-get($breakpoints, tiny) * .8;
	z-index: 600;
	bottom: 0;
	z-index: 99999;
	overflow-y: scroll;
	max-width:100%;
	padding-top: rem(30px);
	background: $light;

	// Desktop Navigation
	//////////////////////////////

	@include breakpoint(large) {
		bottom:auto;
		background:transparent;
		left:auto;
		top:auto;
		padding-top: 0;
		z-index: 99;
		overflow-y: visible;
		position: relative;
		width: 100%;
		border-bottom: rem(1px) solid rgba($dark, 0.2);
		margin-bottom: rem(20px);
		padding-bottom: rem(20px);
	}
}

.is_stuck {
	width: 100% !important;
	padding: rem(20px) rem(120px) ;
	left: 0;
	background:$dark;

	nav {
		display: flex;
		justify-content: space-between;

		li.handwerk,
		li.rahmen {
			display: block;
		}

		a {
			color: $light;

			&:hover {
				color: $secondary;
			}
		}
	}
}

body.cmsbackend { .page-navi { z-index: 88;}}

.mobile-bar {
	align-items:center;
	background:$dark;
	display: flex;
	height:rem($menu-bar);
	justify-content:stretch;
	left:0;
	position: fixed;
	right:0;
	top:0;
	z-index: 894;

	li {
		display:flex;
		flex-grow:1;
		color: $light;
		padding:0 rem($base-gap);


		&:last-child {
            justify-content:flex-end;
        }

        a {
        	text-decoration: none;
        	font-size: rem(16px);
        }
	}

	@include breakpoint(large) {
		display: none;
	}
}

label[for="navi-toggled"] {
	@extend .fa-bars;
	flex-direction:row-reverse;
	font-size: rem(18px);

	&:before {
		@extend .fa-default;
		margin-right: rem(10px);
	}

	&:after {
		background:rgba($dark,0.8);
		bottom:0;
		content:'';
		left:0;
		opacity: 0;
		pointer-events:none;
		position: fixed;
		top:rem($menu-bar);
		transition:opacity .6s;
		width: 100%;
		z-index:-1;
	}

}

#navi-toggled {
	display: none;

	&:checked {

		~ * label[for="navi-toggled"] {
			@extend .fa-close;

			&:after {
				opacity: 1;
				pointer-events:auto;
			}
		}

		~ * .page-navi,
		~ .page-navi {
			left:0;
		}
	}
}

.navi-add {
	display: none;

	@include breakpoint(large) {
		display: flex;
		justify-content: space-between;
	 }
	

	li {
		font-size: rem(17px);
		 
		&:hover {
			> a {
				color: $secondary;
			}
		}

		 &.active {
			a {
				color: $secondary;
			}
		}

		a {
			color: $light;
			display: block;
			font-size: rem(16px);
			text-decoration: none;
		}
	}
}
